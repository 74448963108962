@import '~@lindsey-software/housing-manager-ux/scss/hm.scss';
@import '~react-datepicker/dist/react-datepicker.css';

:target, .scroll-target {
  scroll-margin-top: 300px;
}

iframe.auto-frame {
  max-height: 50rem;
  width: 100%;
  min-height: 10rem;
  overflow: auto;
}

.input-currency > .hm-field {
  max-width: 10rem;
  min-width: 6rem;
}


.react-datepicker {
  display: flex;

  &-popper {
    z-index: 1001;
  }

  .showTimeInput & {
    flex-flow: column;
  }

  &__time-container {
    width: auto;
    white-space: nowrap;

  }

  &__input-time-container {
    display: flex;
    align-items: center;
    justify-items: stretch;
    margin: 0;
    padding: .5rem .5rem;

    .react-datepicker-time__input-container {
      display: block;
      flex: 1 1 auto;
    }

    .react-datepicker-time__caption {
      flex-basis: content;
      padding-right: .5rem;
      margin: 0;
    }

    div.react-datepicker-time__input {
      display: block;
      margin: 0;
    }

    input {
      @extend .form-control;
      width: 100% !important;
    }
  }

  &__time &__time-box {
    width: auto !important;
  }


}
.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 6.5rem;
}

.input-group > .react-datepicker-wrapper {
  flex: 1 1 auto;
  width: 1%;

  .react-datepicker__input-container {
    display: block;
  }

  .form-control {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

#root {
  @extend .d-flex;
  flex-direction: column;

  margin: 0;
  padding: 0;

  .splash-page & {
    @include media-breakpoint-up(md) {
      height: 100%;
      display: flex;
    }

    .splash-body { flex: 1 1 100%; + div { display: none;} }

    #hmHeader {
      flex: 0 0 1%;
    }

    #hmFooter {
      padding: .5rem 1rem;
      display: flex;

      justify-content: space-between;

      a {
        color: $dark;
        margin: 0 .5rem;
      }

      @include media-breakpoint-down(md) {
        font-size: .9em;
        display: block;
        margin-top: 1rem;
        text-align: center;

        .copy { white-space: nowrap; }

        > div {
          margin-top: 1rem;
        }
      }
    }
   }

 }


.jsondiffpatch-modified .jsondiffpatch-property-name {
  font-weight: bold;
}

.reactselect-is-invalid ~ .invalid-feedback,
.input-group.is-invalid ~ .invalid-feedback {
  display: block;
}

//removes caret from the plus button
.plus .dropdown-toggle::after {
  display: none;
}

// billing contacts icon (in select component)
.bc-icon {
  background: #e9ecef;
  align-self: stretch;
  display: flex;
  align-content: center;
  justify-items: center;
  padding: 0.5rem 0.7rem;
  border-right: 1px solid #ced4da;
  margin-top: -2px;
  margin-bottom: -2px;
  margin-left: -8px;
  margin-right: 8px;
}

.react-select-is-invalid ~ .invalid-feedback {
  display: block;
}

.is-editable {
  .react-datepicker-wrapper {
    position: relative;
    z-index: 1;
  }
}


.app-status-detail {
  @extend .d-flex;
  flex-wrap: wrap;
  > * {
    margin-right: .5rem;
  }
  .max-applicants {
    @extend .text-dark;

    width: 100%;
    flex-grow: 1;
    flex-basis: 100%;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  #hmNav-cont #hmNav {
    justify-content: flex-start;
  }
 }

/* Styles above are specific to components used in My.HM (they should not be ported to UX) */




/* styles to back-port to UX follow... */

/* end UX back-ported styles */